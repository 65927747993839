// @font-face {
//   font-family: 'SF pro text';
//   font-style: normal;
//   font-weight: 400;
//   font-display: swap;
//   src: url('./fonts/SFProText-Regular.ttf') format('truetype');
// }
// @font-face {
//   font-family: 'SF pro display';
//   font-style: normal;
//   font-weight: 800;
//   font-display: swap;
//   src: url('./fonts/SFProDisplay-Heavy.ttf') format('truetype');
// }

.Main-layout {
  font-family: var(--p-font-family-sans), sans-serif;
  .Polaris-Button {
    font-family: var(--p-font-family-sans), sans-serif ;
  }
}
