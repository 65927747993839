.filter-box-wrapper {
  padding: var(--p-space-300);
  background: #f7f7f7;
  border-radius: var(--p-border-radius-200);
}

.badge-item-wrapper {
  padding: 2px 6px;
  background: #e3e3e3;
  border-radius: var(--p-border-radius-200);
  color: #303030;

  &.conflict-error {
    background-color: var(--p-color-bg-fill-critical-secondary);
    color: var(--p-color-text-critical);
  }
}
