.plansSection {
  padding-bottom: 4rem;
  // margin-top: 20px;
  &.blackFriday {
    .Polaris-Grid-Cell:last-child {
      .Polaris-Card {
        background-color: black;
        color: white;
      }
      .Polaris-TextStyle--variationSubdued {
        color: white;
      }
    }
  }
}
.flexIconAndText {
  display: flex;
  p {
    margin-bottom: 7px;
  }
  .Polaris-Icon {
    margin: 0;
    margin-top: 5px;
    margin-inline-end: 10px;
  }
}
.flexIconAndTextInTitle {
  display: flex;
  align-items: center;
  .Polaris-Icon {
    margin: 0;
    margin-inline-end: 10px;
  }
}
.discountBox {
  position: relative;
  .drp {
    // position: absolute;
    right: 0;
    top: 100%;
    min-width: 250px;
    .Polaris-Card {
      padding-left: 16px;
      padding-right: 16px;
      padding-bottom: 18px;
      padding-top: 10px;
      display: block;
    }
    img {
      cursor: pointer;
    }
  }

  .discount-code-header {
    display: flex;
    justify-content: space-between;

    span {
      margin-bottom: 10px;
    }
  }
}
