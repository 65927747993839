.toggle-switch {
  position: relative;
  width: 40px;
  display: inline-block;
  text-align: left;

  .checkbox {
    display: none;

    &:checked + .label .inner {
      margin-left: 0;
    }
    &:checked + .label .switch {
      right: 0px;
    }
  }

  .label {
    display: block;
    overflow: hidden;
    cursor: pointer;
    border: 0 solid rgba(#13141f, 0.34);
    border-radius: 20px;

    .inner {
      display: block;
      width: 200%;
      margin-left: -100%;
      transition: margin 0.3s ease-in 0s;

      &:before,
      &:after {
        float: left;
        width: 50%;
        height: 24px;
        padding: 0;
        line-height: 24px;
        color: #fff;
        font-weight: bold;
        box-sizing: border-box;
      }
      &:before {
        content: '';
        padding-left: 10px;
        background-color: #2a6550;
        color: #fff;
      }
      &:after {
        content: '';
        padding-right: 10px;
        background-color: #bbb;
        color: #fff;
        text-align: right;
      }
    }

    .switch {
      display: block;
      width: 20px;
      margin: 2px;
      background: #fff;
      position: absolute;
      top: 0;
      bottom: 0;
      right: 16px;
      border: 0 solid #bbb;
      border-radius: 20px;
      transition: all 0.3s ease-in 0s;
    }
  }
}
