.black-friday-wrapper {
  border-radius: 16px;
  background: linear-gradient(106deg, #1d2e3b 1.37%, #071a28 100%);
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.24);
  width: 100%;
  color: #fff;
  display: flex;

  .box-wrapper {
    padding: 24px;
    display: flex;
    align-items: center;
  }

  .divider {
    border-right: 1px solid rgba(255, 255, 255, 0.3);
  }

  .red-color {
    color: #ff4848;
  }

  .white-color {
    color: #fff;
  }

  .light-red-color {
    color: #fbb;
  }

  .campaign-title {
    padding-left: 30%;
  }

  @media screen and (max-width: 768px) {
    .campaign-title {
      padding-left: 0;
    }
  }
}
