.plan-card {
  margin: 20px auto;
  height: 100%;
  font-family: var(--p-font-family-sans), sans-serif;
  .Polaris-Card {
    height: 100%;
    .Polaris-Heading {
      font-family: var(--p-font-family-sans), sans-serif;
      font-weight: bold;
      // font-size: 18px;
    }
    .Polaris-DisplayText--sizeExtraLarge {
      font-family: 'SF pro display';
    }
  }
}
.Polaris-ShadowBevel {
  border-radius: var(--p-border-radius-200);
  height: 100% !important;
}
.recommended-plan {
  .Polaris-Box {
    border-radius: 0 0 var(--p-border-radius-200) var(--p-border-radius-200);
    // background-color: #006e52;
    color: black !important;
  }

  .Polaris-ShadowBevel {
    --pc-shadow-bevel-box-shadow-xs: none !important;
    --pc-shadow-bevel-border-radius-xs: 0 !important;
  }

  .Polaris-TextStyle--variationStrong {
    color: white !important;
  }
  .Polaris-TextStyle--variationSubdued {
    color: white !important;
  }
  position: relative;
  &:before {
    content: 'RECOMMENDED';
    font-size: 12px;
    font-weight: 700;
    position: absolute;
    width: 100%;
    background-color: var(--p-color-bg-fill-info);
    color: black;
    border-radius: 10px 10px 0 0;
    top: 0;
    text-align: center;
    transform: translateY(-29px);
    border-bottom: 1px solid white;
    padding: 4px 0;
  }
}
.Current-plan {
  font-size: 13px;
  font-weight: bold;
  &:before {
    content: '';
    width: 10px;
    height: 10px;
    margin-inline-end: 10px;
    vertical-align: middle;
    display: inline-block;
    background-color: var(--p-color-bg-fill-brand);
    border-radius: 50%;
  }
}
.description {
  margin: 10px auto;
  text-align: center;
  line-height: 3.35em;
  .Polaris-DisplayText--sizeSmall {
    font-size: 16px;
    line-height: 1.5em;
  }
}
.price-box {
  display: flex;
  flex-direction: column;
  height: 5em;
  justify-content: center;
  align-items: center;
  .Polaris-DisplayText {
    font-size: 2.7em;
  }
}

.oldPrice {
  font-size: 2em;
  margin-bottom: 10px;
  text-align: center;
  font-weight: bold;
  color: #6d7175;
}

.card-Models {
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 18px;
  display: block;
}
