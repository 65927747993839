.checkbox-wrapper {
  .checkbox-item {
    border-radius: 6px;
    cursor: pointer;
    padding: 6px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 4px;

    &.active {
      background-color: #ebebeb;
    }
  }
}
